<template lang="pug">
.v-available.available(v-if="data")
	.available__header(id="map")
		h2.available__title.h2(v-if="data.title") {{data.title}}
		.available__filters(v-if="data.filters")
			.available__filter(v-for="(filter, filterInd) in data.filters" :key="filterInd")
				v-field.available__filter-field(:field="filter"
					appearance="form"
					v-model="filter.value"
					@update:model-value="filterChanged(filter, $event)")
	.available__tabs(v-if="data.tabs" )
		button.section__tab-btn.btn.btn--md(v-for="(tab, index) in data.tabs"
				:key="index"
				@click="setActiveTab(tab.tabId)"
				:class="{'btn--primary': tab.tabId === acviveTabId}")
			| {{ tab.text }}
	.available__wrap(v-if="data.items && acviveTabId === 'map'" :class="{'map-view': acviveTabId === 'map'}")
		v-map.available__map(:markers="data.items" :coords="data.mapCenterCoords" :zoom="data.mapZoom" @click-marker="clickMarkerHandler" clusters ref="map")
	.available__wrap(v-if="data.items && acviveTabId === 'list'" :class="{'list': acviveTabId === 'list'}")
		v-available-table.available__table(:data-source="data")

</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null,
			acviveTabId: null,
			localFilter: null
			// timerId: null
		};
	},

	methods: {
		initFilter() {
			let result = []
			this.data.filters.forEach(filter => result.push({[filter.name]: filter.value}))
			return result
		},

		setActiveTab(id) {
			this.acviveTabId = id
		},

		// getFiltersValue() {
		// 	let result = {};
		// 	console.log('getFiltersValuem', this.table, this.table.filters, this.table.filters.length)
		// 	if (this.table && this.table.filters && this.table.filters.length > 0) {
		// 		this.table.filters.forEach(filter => {
		// 			console.log('filter', filter)
		// 			result[filter.name] = {
		// 				value: filter.value,
		// 				type: filter.type
		// 			};
		// 		});
		// 	}
		// 	return result;
		// },
		//
		// filterChanged() {
		// 	clearTimeout(this.timerId);
		// 	this.timerId = setTimeout(() => {
		// 		this.dataLoad({
		// 			url: this.data.remote,
		// 			delay: true,
		// 			data: {
		// 				filters: this.getFiltersValue()
		// 			}
		// 		});
		// 	}, 1500)
		// },

		filterChanged(filter, value) {
			const finalFilter = {...this.localFilter, ...[{[filter.name]: value}]}
			this.dataLoad({
				url: this.data.remote,
				delay: true,
				data: {
					filters: finalFilter
				}
			});
		},

		dataSet(data) {
			this.data = data;
			this.acviveTabId = this.data?.tabs.find(tab => tab.active === true).tabId || null
			this.localFilter = this.initFilter()
		}
	}
};
</script>
