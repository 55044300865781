// helpers
import VScope from '../components/helpers/v-scope.vue';
import VSource from '../components/helpers/v-source.vue';
import VModelSource from '../components/helpers/v-model-source.vue';
import VLoader from '../components/helpers/v-loader.vue';
import VSvgIcon from '../components/helpers/v-svg-icon.vue';
import VImage from '../components/helpers/v-image.vue';
import VVideo from '../components/helpers/v-video.vue';
import VLazyComponent from '../components/helpers/v-lazy-component.vue';
import VCollapse from "../components/helpers/v-collapse.vue";
import VAccordion from "../components/helpers/v-accordion.vue";

// forms
import VForm from '../components/v-form.vue';
import VBigForm from '../components/v-big-form.vue';
import VSubscriptionForm from '../components/v-subscription-form.vue';
import VField from '../components/v-field.vue';
import VFieldText from '../components/v-field-text.vue';
import VFieldSelect from '../components/v-field-select.vue';
import VFieldCheckbox from '../components/v-field-checkbox.vue';
import VFieldCheckList from '../components/v-field-check-list.vue';
import VFieldCount from '../components/v-field-count.vue';
import VFieldRange from '../components/v-field-range.vue';
import VFieldRangeSlider from '../components/v-field-range-slider.vue';
import VFieldFile from '../components/v-field-file.vue';
import VFieldViewPicker from '../components/v-field-view-picker.vue';
import VRating from '../components/v-rating.vue';
import VFieldPhone from '../components/v-field-phone.vue';
import VFieldRadio from '../components/v-field-radio.vue';
import VFieldTags from '../components/v-field-tags.vue';
import VFieldTel from '../components/v-field-tel.vue';

// blocks
import VPageSection from '../components/v-page-section.vue';
import VPageSectionHeader from '../components/v-page-section-header.vue';
import VHeroBlock from '../components/v-hero-block.vue';
import VHeroSlider from '../components/v-hero-slider.vue';
import VPrice from '../components/v-price.vue';
import VStatus from '../components/v-status.vue';
import VCatalog from '../components/v-catalog.vue';
import VFilter from '../components/v-filter.vue';
import VFilterActiveItems from '../components/v-filter-active-items.vue';
import VPageNav from '../components/v-page-nav.vue';
import VAvailability from '../components/v-availability.vue';
import VTopNav from '../components/v-top-nav.vue';
import VMainNav from '../components/v-main-nav.vue';
import VMobileNav from '../components/v-mobile-nav.vue';
import VPageHeader from '../components/v-page-header.vue';
import VBasketSmall from '../components/v-basket-small.vue';
import VBasketAction from '../components/v-basket-action.vue';
import VFavoriteSmall from '../components/v-favorite-small.vue';
import VProductGrid from '../components/v-product-grid.vue';
import VProductList from '../components/v-product-list.vue';
import VProductLine from '../components/v-product-line.vue';
import VProductSlider from '../components/v-product-slider.vue';
import VProduct from '../components/v-product.vue';
import VProductImage from '../components/v-product-image.vue';
import VArticleGrid from '../components/v-article-grid.vue';
import VArticlePoster from '../components/v-article-poster.vue';
import VCatalogItem from '../components/v-catalog-item.vue';
import VSearchSmall from '../components/v-search-small.vue';
import VModal from '../components/v-modal.vue';
import VModalWindow from '../components/v-modal-window.vue';
import VGallery from '../components/v-gallery.vue';
import VSwitcher from '../components/v-switcher.vue';
import VNeeds from '../components/v-needs.vue';
import VOffers from '../components/v-offers.vue';
import VQuestions from '../components/v-questions.vue';
import VArticlesGrid from '../components/v-articles-grid.vue';
import VBrands from '../components/v-brands.vue';
import VBaseSlider from '../components/v-base-slider.vue';
import VTextSlider from '../components/v-text-slider.vue';
import VDocList from '../components/v-doc-list.vue';
import VDoubleSlider from '../components/v-double-slider.vue';
import VYmap from '../components/v-ymap.vue';
import VMap from '../components/v-map.vue';
import VBtnTop from '../components/v-btn-top.vue';
import VProductDescr from '../components/v-product-descr.vue';
import VVacancies from '../components/v-vacancies.vue';
import VCertification from '../components/v-certification.vue';
import VContacts from '../components/v-contacts.vue';
import VBlueTabs from '../components/v-blue-tabs.vue';
import VCatalogBanner from '../components/v-catalog-banner.vue';
import VBreadcrumbs from '../components/v-breadcrumbs.vue';
import VTabs from '../components/v-tabs.vue';
import VSmallSlider from '../components/v-small-slider.vue';

// plugins
import VSwiperSlider from '../components/v-swiper-slider.vue'; // https://swiperjs.com/vue/
import {SwiperSlide} from 'swiper/vue';

export default {
	// helpers
	'v-scope': VScope,
	'v-source': VSource,
	'v-model-source': VModelSource,
	'v-loader': VLoader,
	'v-svg-icon': VSvgIcon,
	'v-image': VImage,
	'v-video': VVideo,
	'v-lazy-component': VLazyComponent,
	'v-collapse': VCollapse,
	'v-accordion': VAccordion,

	// forms
	'v-form': VForm,
	'v-big-form': VBigForm,
	'v-subscription-form': VSubscriptionForm,
	'v-field': VField,
	'v-field-text': VFieldText,
	'v-field-select': VFieldSelect,
	'v-field-checkbox': VFieldCheckbox,
	'v-field-check-list': VFieldCheckList,
	'v-field-count': VFieldCount,
	'v-field-range': VFieldRange,
	'v-field-range-slider': VFieldRangeSlider,
	'v-field-file': VFieldFile,
	'v-field-view-picker': VFieldViewPicker,
	'v-field-tags': VFieldTags,
	'v-rating': VRating,
	'v-field-phone': VFieldPhone,
	'v-field-radio': VFieldRadio,
	'v-field-tel': VFieldTel,

	// blocks
	'v-page-section': VPageSection,
	'v-page-section-header': VPageSectionHeader,
	'v-hero-block': VHeroBlock,
	'v-hero-slider': VHeroSlider,
	'v-price': VPrice,
	'v-status': VStatus,
	'v-catalog': VCatalog,
	'v-filter': VFilter,
	'v-filter-active-items': VFilterActiveItems,
	'v-page-nav': VPageNav,
	'v-availability': VAvailability,
	'v-top-nav': VTopNav,
	'v-main-nav': VMainNav,
	'v-mobile-nav': VMobileNav,
	'v-page-header': VPageHeader,
	'v-basket-small': VBasketSmall,
	'v-basket-action': VBasketAction,
	'v-favorite-small': VFavoriteSmall,
	'v-product-grid': VProductGrid,
	'v-product-list': VProductList,
	'v-product-line': VProductLine,
	'v-product-slider': VProductSlider,
	'v-product': VProduct,
	'v-product-image': VProductImage,
	'v-article-grid': VArticleGrid,
	'v-article-poster': VArticlePoster,
	'v-catalog-item': VCatalogItem,
	'v-search-small': VSearchSmall,
	'v-modal': VModal,
	'v-modal-window': VModalWindow,
	'v-gallery': VGallery,
	'v-switcher': VSwitcher,
	'v-needs': VNeeds,
	'v-offers': VOffers,
	'v-questions': VQuestions,
	'v-articles-grid': VArticlesGrid,
	'v-brands': VBrands,
	'v-base-slider': VBaseSlider,
	'v-text-slider': VTextSlider,
	'v-doc-list': VDocList,
	'v-double-slider': VDoubleSlider,
	'v-ymap': VYmap,
	'v-map': VMap,
	'v-btn-top': VBtnTop,
	'v-product-descr': VProductDescr,
	'v-vacancies': VVacancies,
	'v-certification': VCertification,
	'v-contacts': VContacts,
	'v-blue-tabs': VBlueTabs,
	'v-catalog-banner': VCatalogBanner,
	'v-breadcrumbs': VBreadcrumbs,
	'v-tabs': VTabs,
	'v-small-slider': VSmallSlider,

	// plugins
	'v-swiper-slider': VSwiperSlider,
	'v-slide': SwiperSlide
};