import scrollLock from 'scroll-lock';
import View from '../view/view';
import mitt from 'mitt';

class Controller {
	constructor() {
		this.events = mitt();

		this.touch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

		this.passiveEventsSupported = (() => {
			// passive event listeners support test from MDN
			let result = false;
			try {
				window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
					get: () => result = true
				}));
			} catch (err) {}
			return result;
		})();

		this.mobileNavData = [];

		document.addEventListener('keydown', (e) => {
			if (e.keyCode == 27) {
				this.closeAll();
			}
		});

		document.querySelector('.js-overlay').addEventListener('click', () => {
			this.closeAll();
		});

		View.initAllGlobal();
		View.initAllLocal(document.body);
	}

	lockScrolling() {
		scrollLock.addFillGapSelector('.state--header-fixed .page-header');
		scrollLock.disablePageScroll(document.querySelectorAll('.modal__surface'));
	}

	unlockScrolling() {
		scrollLock.enablePageScroll(document.querySelectorAll('.modal__surface'));
	}

	openMobileNav() {
		this.events.emit('mobile-nav-open');
	}

	closeMobileNav() {
		this.events.emit('mobile-nav-close');
	}

	updateMobileNav(data) {
		this.mobileNavData.push(data);
		this.events.emit('mobile-nav-updated', this.mobileNavData);
	}

	openCatalogAside() {
		document.body.classList.add('state--catalog-aside-open');
	}

	closeCatalogAside() {
		document.body.classList.remove('state--catalog-aside-open');
	}

	openSearchSmall() {
		this.events.emit('search-small-open');
	}

	closeSearchSmall() {
		this.events.emit('search-small-close');
	}

	focusSearch() {
		this.events.emit('search-small-focus');
	}

	openModal(options) {
		if (window.modalController) {
			window.modalController.open(options);
		}
	}

	closeModal() {
		if (window.modalController) {
			window.modalController.close();
		}
	}

	closeAllModals() {
		if (window.modalController) {
			window.modalController.closeAll();
		}
	}

	closeAll() {
		this.closeMobileNav();
		this.closeCatalogAside();
		this.closeMobileNav();
		this.closeSearchSmall();
		this.closeAllModals();
	}
}

export default new Controller();