<template lang="pug">
.v-ymap.ymap(v-if="data")
	.ymap__container.container
		h2.ymap__title.h2(v-if="data.title") {{data.title}}
		.ymap__wrap
			v-map.ymap__map.flc(:markers="data.items" :coords="data.mapCenterCoords" :zoom="data.mapZoom" @click-marker="clickMarkerHandler" clusters ref="map")
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';

export default {
	mixins: [
		sourceMixin
	],

	data() {
		return {
			data: null
		};
	},

	methods: {
		clickMarkerHandler() {

		},

		dataSet(data) {
			this.data = data;
		}
	}
};
</script>