<template lang="pug">
.v-field.field(:class="{'form-error': field && field.error}")

	template(v-if="field.type == 'select'")
		v-field-select(:field="field" :model-value="localValue" @update:model-value="handleInput")

	template(v-else-if="field.type == 'check-list'")
		v-field-check-list(:field="field" :model-value="localValue" @update:model-value="handleInput")

	template(v-else-if="field.type == 'radio-list'")
		v-field-check-list(:field="field" radio :model-value="localValue" @update:model-value="handleInput")

	template(v-else-if="field.type == 'count'")
		v-field-count(:field="field" :model-value="localValue" @update:model-value="handleInput")

	template(v-else-if="field.type == 'range'")
		v-field-range(:field="field" :model-value="localValue" @update:model-value="handleInput")

	template(v-else-if="field.type == 'radio'")
		v-field-radio(:field="field" :model-value="localValue" @update:model-value="handleInput")

	template(v-else-if="field.type == 'range-slider'")
		v-field-range-slider(:field="field" :model-value="localValue" @update:model-value="handleInput")

	template(v-else-if="field.type == 'rating'")
		v-rating(:field="field" v-model="localValue" @update:model-value="handleInput" input)

	template(v-else-if="field.type == 'phone'")
		v-field-phone(:field="field" @fileinput="fileInput" @fileremove="fileRemove" @update:model-value="handleInput")

	template(v-else-if="field.type == 'tel'")
		v-field-tel(:field="field" :model-value="localValue" :disabled="field.disabled" @update:model-value="handleInput")

	template(v-else-if="field.type == 'file'")
		v-field-file(:field="field" @fileinput="fileInput" @fileremove="fileRemove")

	template(v-else-if="field.type == 'view-picker'")
		v-field-view-picker(:field="field" :model-value="localValue" @update:model-value="handleInput" :disabled="field.disabled")

	template(v-else-if="field.type == 'tags'")
		v-field-tags(:field="field" :model-value="localValue" @update:model-value="handleInput" :disabled="field.disabled")

	template(v-else-if="field.type == 'search'")
		v-field-text.field-text--search(:field="field" :model-value="localValue" :disabled="field.disabled" @update:model-value="handleInput" ref="field-search")

	template(v-else)
		v-field-text(:field="field" :model-value="localValue" :disabled="field.disabled" @update:model-value="handleInput")

	.field__error(v-if="field.error") {{field.error}}
</template>

<script>
export default {
	props: {
		field: {
			type: Object,
			default: null,
			required: true
		},

		modelValue: {
			type: [Object, String, Number, Boolean, Date],
			default: null
		}
	},

	emits: [
		'fileinput',
		'fileremove',
		'update:model-value'
	],

	data() {
		return {
			localValue: null
		};
	},

	watch: {
		modelValue: {
			immediate: true,
			handler() {
				this.updateLocalValue();
			}
		}
	},

	methods: {
		focusSearch() {
			this.$refs['field-search']?.focus();
		},

		fileInput(e) {
			this.$emit('fileinput', e);
		},

		fileRemove(e) {
			this.$emit('fileremove', e);
		},

		updateLocalValue() {
			this.localValue = this.modelValue;
		},

		handleInput(value, field = this.field) {
			this.localValue = value;
			this.$emit('update:model-value', this.localValue, field);
		}
	}
};
</script>