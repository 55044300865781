<template lang="pug">
.v-vacancies.vacancies(v-if="vacancies")
	.vacancies__container.container
		.vacancies__wrap
			h1.vacancies__title.h2(v-if="vacancies.title") {{vacancies.title}}
			.vacancies__main
				.vacancies__left
					.vacancies__items-wrap
						template(v-if="vacancies.items && vacancies.items.length")
							template(v-for="(item, index) in vacancies.items" :key="index")
								.questions__col.questions__col--full
									.questions__item.questions__item--white(@click="openItem(item)" :class="{'questions__item--active': item.isOpen}")
										.questions__item-heading
											.questions__item-question {{item.name}}
											.questions__alt-btn(:class="{'close': !item.isOpen}")
												v-svg-icon.questions__alt-icon(icon="chevron-down")
										v-collapse.questions__collapse(v-if="item.text" :open="item.isOpen")
											.questions__collapse
												.questions__item-text-wrap
													.questions__item-text.text-guide(v-html="item.text")
													.questions__additionalw
														.questions__additional-item.flc
															.article__author.flc
																img.article__author-icon(:src="noteIcon" alt="" loading="lazy")
																.article__author-text {{item.location}}
														.questions__additional-item.flc
															.article__author.flc
																img.article__author-icon(:src="moneyIcon" alt="" loading="lazy")
																.article__author-text {{item.note}}
						template(v-else-if="vacancies.emptyMessage")
							.vacancies__empty-message
								.vacancies__empty-message-title(v-if="vacancies.emptyMessage.title") {{vacancies.emptyMessage.title}}
								.vacancies__empty-message-text.flc(v-if="vacancies.emptyMessage.text") {{vacancies.emptyMessage.text}}
								img.vacancies__empty-message-image.flc(v-if="vacancies.emptyMessage.image" :src="vacancies.emptyMessage.image" alt="" loading="lazy")

				.vacancies__right
					.vacancies__form-wrap
						slot
</template>

<script>
import sourceMixin from '../components/mixins/v-mixin-source.vue';
import controller from '../scripts/controller/controller.js';

export default {
	mixins: [
		sourceMixin
	],

	props: {
		mode: {
			type: String,
			default: null
		}
	},

	data() {
		return {
			vacancies: null,
			noteIcon: "/local/images/location-btn-icon.svg",
			moneyIcon: "/local/images/money-icon.svg"
		};
	},

	methods: {
		openItem(vacancy) {
			this.vacancies.items.forEach(item => {
				if (item.id !== vacancy.id) {
					item.isOpen = false;
				}
			});
			vacancy.isOpen = !vacancy.isOpen;
		},

		dataSet(data) {
			this.vacancies = data;
		}
	}
};

</script>